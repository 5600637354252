export let Settings = {
    "BSC": {
        StackPlus: {
            "rake": {
                token: "0xbDa8D53fe0F164915b46cd2EcfFD94254b6086a2",
                stack: "0x07ff7dF1B59847F41df764eE7a145346938D4559",
                block: 5789885,
                timestamp: 1616075466,
                symbol: "rake",
                decimal: 18,
                padding: 5
            },
            "br34p": {
                token: "0xa86d305a36cdb815af991834b46ad3d7fbb38523",
                stack: "0xE3360D0d4ae1F897c03D974099DD39B18B843818",
                block: 5789918,
                timestamp: 1616075466,
                symbol: "br34p",
                decimal: 8,
                padding: 5
            },
        }
    }
}