<template>
  <div class="section footer mb-0">
    <div class="container">
      <div class="row footer-row">
        <div class="col-12 text-right">
          <a class="btn btn-circle btn-outline-semi-light footer-circle-button scrollTo" href="#home" id="footerCircleButton"><i
              class="simple-icon-arrow-up"></i></a>
        </div>
        <div class="col-12 text-center footer-content">
          <a href="#home" class="scrollTo">
            <img class="footer-logo" alt="footer logo" src="../../src/assets/img/landing-page/Logo.png"/>
          </a>
        </div>
      </div>
      <div class="row" id="footerMenuAccordion">
      </div>
    </div>
    <div class="separator mt-5"></div>
    <div class="container copyright pt-5 pb-5">
      <div class="row">
        <div class="col-6">
          <p class="mb-0">2021 © HYDRATION NETWORK</p>
        </div>
        <div class="col-6 text-right social-icons">
          <p><a href="https://t.me/Br34P_Chat" class="footer-link"><img
              class="footer-telegram" src="../../src/assets/img/telegram-logo.svg"/> Join us on Telegram</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "foot"
}
</script>

<style scoped>

</style>