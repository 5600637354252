<template>
  <nav class="landing-page-nav">
    <div class="container d-flex align-items-center justify-content-between">
      <a class="pull-left" href="/">
        <img width="80px" class="mr-2" src="../assets/img/landing-page/Logo.png" /><span class="text-white header-text">SILO</span>
      </a>

      <a href="#" class="d-lg-flex mobile-menu-button">
        <i class="simple-icon-menu"></i>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "topNav"
}
</script>

<style scoped>

</style>